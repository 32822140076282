<script>
export default {
  mounted() {
    this.mountData()
  },
  methods: {
    async mountData() {
      await this.$http.get('features/parts/parent/list')
        .then(response => { this.pageArray = response.data })
    }
  },
  data() {
    return {
      pageArray: [],
    }
  }
}
</script>

<template>
  <section class="pb-2">
    <div class="tb-card">
      <a class="tb-page-title">Car Part Types</a>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
          <tr>
            <th style="width: 5%"><input type="text" class="form-control" placeholder="#" disabled readonly></th>
            <th style="width: 65%"><input type="text" class="form-control" placeholder="Part Type Name" disabled
                readonly></th>
            <th style="width: 15%"><input type="text" class="form-control" placeholder="Part Type Enum" disabled
                readonly></th>
            <th style="width: 15%"><input type="text" class="form-control" placeholder="Options" disabled readonly></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in pageArray">
            <td><a>{{ index + 1 }}</a></td>
            <td><a>{{ item.typeName }}</a></td>
            <td><a>{{ item.typeEnum }}</a></td>
            <td>
              <button class="tb-button bg-success"
                @click="$router.push(`/carParts/${$route.params.modelId}/${item.typeEnum}`)">
                <span class="text-white">Inside</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>